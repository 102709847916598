export const systemLevelActionsToDescriptionMap = {
  'users:read': 'Permission to view users',
  'users:write': 'Permission to create and update users',
  'users:delete': 'Permission to delete users',
  'applications:read': 'Permission to view applications',
  'applications:write': 'Permission to create and update applications',
  'applications:delete': 'Permission to delete applications',
  'config-management:read': 'Permission to view config management data',
  'config-management:write': 'Permission to create and update config management data',
  'config-management:delete': 'Permission to delete config management data',
  'presentation-data-management:read': 'Permission to view presentation data management data',
  'presentation-data-management:delete': 'Permission to delete presentation data management data',
  'integrations:read': 'Permission to view integrations',
  'integrations:write': 'Permission to create and update integrations',
  'integrations:delete': 'Permission to delete integrations',
  'content-management:read': 'Permission to view content management data',
  'content-management:write': 'Permission to create and update content management data',
  'content-management:delete': 'Permission to delete content management data',
  'webhook-trigger:write': 'Permission to trigger webhooks',
  'data-clean-up:delete': 'Permission to clean up(bulk-delete) data',
};

export const systemLevelActionsPermissionsMap = {
  'users:read': 'ReadUser',
  'users:write': 'CreateUser',
  'users:delete': 'DeleteUser',
  'applications:read': 'ReadApplication',
  'applications:write': 'CreateApplication',
  'applications:delete': 'DeleteApplication',
  'integrations:read': 'ReadIntegrations',
  'integrations:write': 'AddIntegration',
  'integrations:delete': 'DeleteIntegration',
  'content-management:read': 'ReadContentManagement',
  'content-management:write': 'AddContentManagement',
  'content-management:delete': 'DeleteContentManagement',
  'webhook-trigger:write': 'TriggerWebhook',
  'data-clean-up:delete': 'DataCleanUp',
};

export type TSystemLevelAction = keyof typeof systemLevelActionsToDescriptionMap;
