import {
  AdvancedFilterModel,
  DateAdvancedFilterModel,
  DateStringAdvancedFilterModel,
  FilterModel,
  NumberAdvancedFilterModel,
  ObjectAdvancedFilterModel,
  SortModelItem,
  TextAdvancedFilterModel,
} from '@ag-grid-community/core';

const filterTypesMap = {
  contains: 'substring',
  equals: 'eq',
  In: 'in',
  notEqual: 'ne',
  endsWith: 'endsWith',
  startsWith: 'startsWith',
  'Less than or equal': 'lte',
  'Less than': 'lt',
  'Greater than or equal': 'gte',
  'Greater than': 'gt',
  Includes: 'contains',
  Like: 'like',
  lessThanOrEqual: 'lte',
  lessThan: 'lt',
  greaterThanOrEqual: 'gte',
  greaterThan: 'gt',
};

type FilterConditions = {
  [key: string]: {
    sort?: string;
    colId?: string;
    filterType?: string | null;
    filters: Array<{ filter: string | null; type: string | null }>;
  };
};

interface Filters {
  [key: string]: any;
}

export type FilterModelType = AdvancedFilterModel | FilterModel | null;

export type ExcludeBooleanFilterModelType =
  | TextAdvancedFilterModel
  | NumberAdvancedFilterModel
  | DateAdvancedFilterModel
  | DateStringAdvancedFilterModel
  | ObjectAdvancedFilterModel;

export type SortModelType = SortModelItem[];

const handleStringType = (key: string, value: string) => [`filter[${key}][eq]`, value];

const handleNumberOrBooleanType = (key: string, value: number | boolean) => [key, value];

const handleSortModelType = (key: string, value: SortModelItem) => [
  `sort[${value.sort}]`,
  value.colId,
];

const handleArrayType = (key: string, value: string[]) => [`filter[${key}][in]`, value];

const handleBooleanAdvancedFilterModel = (key: string, value: boolean) => [
  `filter[${key}][eq]`,
  value,
];

const handleSearchModel = (filterKey: string, filterValue: any, multiFilterOperator: any) => {
  const searchModel = filterValue;

  if (
    searchModel?.filterType === 'object' &&
    'filters' in searchModel &&
    typeof searchModel.filters[0].filter === 'string'
  ) {
    const filterValues = searchModel.filters.map((filter: any) => filter.filter);
    const filterOperator = filterKey === 'id' ? 'in' : 'contains';
    const [firstValue, ...remainingValues] = filterValues;
    remainingValues.forEach((value: any, index: any) => {
      multiFilterOperator[`filter[${filterKey}][${filterOperator}][${index + 1}]`] = value.trim();
    });
    return [`filter[${filterKey}][${filterOperator}][0]`, firstValue.trim()];
  }

  const filterResults = searchModel.filters.reduce((acc: any, filter: any) => {
    const filterTypeKey = filterTypesMap[filter.type as keyof typeof filterTypesMap];
    const filterKeyWithType = `filter[${filterKey}][${filterTypeKey}]`;
    acc[filterKeyWithType] = acc[filterKeyWithType]
      ? `${acc[filterKeyWithType]}|${filter.filter}`
      : filter.filter;
    return acc;
  }, {});

  const [firstFilterEntry, ...remainingFilterEntries] = Object.entries(filterResults);
  remainingFilterEntries.forEach(([key, value]) => {
    multiFilterOperator[key] = value;
  });

  return firstFilterEntry;
};

export const filterParamsToQueryParams = (
  filterParams: Record<
    string,
    string | number | boolean | string[] | FilterModelType | SortModelType | undefined
  > = {}
) => {
  if (filterParams?.type || filterParams?.conditions) {
    return filterParamsToQueryParamsAGgrid(filterParams);
  }
  let inOperatorMultifilter: any = {};
  return {
    ...Object.fromEntries(
      Object.entries(filterParams).map(([key, value]) => {
        if (typeof value === 'string') {
          return handleStringType(key, value);
        }

        if (typeof value === 'number' || typeof value === 'boolean') {
          return handleNumberOrBooleanType(key, value);
        }
        if (value && typeof value === 'object' && 'sort' in value && 'colId' in value) {
          return handleSortModelType(key, value as SortModelItem); // Update the type of value to SortModelItem
        }
        if (Array.isArray(value)) {
          return handleArrayType(key, value as string[]); // Update the type of value to string[]
        }
        // assumes that this is a search model
        if (value && typeof value === 'object' && 'filter' in value) {
          return handleSearchModel(
            key,
            value as ExcludeBooleanFilterModelType,
            inOperatorMultifilter
          );
        }

        return [`filter[${key}]`, value];
      })
    ),
    ...inOperatorMultifilter,
  };
};

export const filterParamsToQueryParamsAGgrid = (
  filterParams: Record<
    string,
    string | number | boolean | string[] | FilterModelType | SortModelType | undefined
  > = {}
) => {
  let inOperatorMultifilter: any = {};

  if (filterParams.type && ['AND', 'OR'].includes(filterParams.type as string)) {
    inOperatorMultifilter['filterOperator'] = filterParams.type;
  }
  let filterConditions: FilterConditions = {};
  //handle default filters
  const expectedKeys = ['type', 'conditions', 'colId', 'filterType', 'filter', 'page'];
  const filterKeys = Object.keys(filterParams).filter((key) => !expectedKeys.includes(key));
  let defaultFilters: Filters = {};
  filterKeys.forEach((key) => {
    defaultFilters[key] = filterParams[key];
  });

  if (filterParams.conditions && Array.isArray(filterParams.conditions)) {
    filterParams.conditions?.forEach((condition: any) => {
      if (condition.colId) {
        if (!filterConditions[condition.colId]) {
          filterConditions[condition.colId] = {
            filterType: condition.filterType,
            filters: [],
          };
        }
        filterConditions[condition.colId].filters.push({
          filter: condition.filter,
          type: condition.type,
        });
      }
    });
  }

  if (filterParams.colId) {
    filterConditions[filterParams.colId as string] = {
      filterType: filterParams.filterType as string,
      filters: [
        {
          filter: filterParams.filter as string,
          type: filterParams.type as string,
        },
      ],
    };
  }
  filterConditions = {
    ...defaultFilters,
    ...filterConditions,
    '0': filterParams['0'] as FilterConditions['0'],
    page: filterParams.page as FilterConditions['page'],
  };
  return {
    ...Object.fromEntries(
      Object.entries(filterConditions).map(([key, value]) => {
        if (typeof value === 'string') {
          return handleStringType(key, value);
        }
        //handles paging
        if (typeof value === 'number' || typeof value === 'boolean') {
          return handleNumberOrBooleanType(key, value);
        }
        //handles sorting
        if (value && typeof value === 'object' && 'sort' in value && 'colId' in value) {
          return handleSortModelType(key, value as SortModelItem); // Update the type of value to SortModelItem
        }
        if (Array.isArray(value)) {
          return handleArrayType(key, value as string[]); // Update the type of value to string[]
        }
        if (value && typeof value === 'object' && value.filterType === 'boolean') {
          return handleBooleanAdvancedFilterModel(key, value.filters[0].type === 'true');
        }
        // assumes that this is a search model
        if (value && typeof value === 'object' && 'filters' in value) {
          return handleSearchModel(key, value, inOperatorMultifilter);
        }

        return [`filter[${key}]`, value];
      })
    ),
    ...inOperatorMultifilter,
  };
};
